
import { Vue, Component, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { emailAddres, required } from "@/cms-services/consts";
import DataList from "@/components/common/data/list.vue";
import { Filter } from "@/cms-services/filter";
import { MailScenarioObjectDataSource } from "@/data/Object/MailScenarioObjectDataSource";
const visibleProperties = [
  "name",
  "template",
  "userTemplate",
  "userCaption",
  "caption",
  "id",
  "createDate",
  "lastUpdate",
  "priority",
  "instanceStateId",
  "scenarioType",
  "objectClassName",
];
@Component({
  components: { DataEditorDialog },
})
export default class Home extends Vue {
  @Ref("dataEditor") dataEditor!: DataEditorDialog;
  @Ref("dataList") dataList!: DataList;

  dataSource: MailScenarioObjectDataSource = new MailScenarioObjectDataSource(
    +this.$route.params.id,
  );

  subsciberDataSource: ListDataSource = new ListDataSource({
    className: "mailsubscriber",
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([
        new Filter("scenarioId", +this.$route.params.id),
      ]),
    },
  });
  $message: any;

  model: any = {
    caption: "",
    description: "",
    email: "",
    scenarioId: +this.$route.params.id,
  };

  fields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        type: "text",
      },
      name: "caption",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        label: "Почта",
        placeholder: "Например info@mail.ru",
        type: "email",
      },
      name: "email",
      validations: [required(), emailAddres()],
    },
    {
      editor: "string",
      attrs: {
        label: "Описание",
        type: "text",
      },
      name: "description",
    },
  ];

  get caption() {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Уведомления",
        to: "/manage/mailscenario/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  get visibleProperties(): any {
    const properties = this.dataSource.metadata?.properties ?? [];
    const cb = (p) => visibleProperties.some((n) => p.name === n);
    return properties.filter(cb);
  }

  async add() {
    const model = await this.dataEditor.update(this.model);
    if (!model) return;

    try {
      await this.subsciberDataSource.add(model);
      if (this.subsciberDataSource.items.length == 1) {
        await this.dataList.refreshData();
      }
      this.$message("Успешно добавлено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    }
  }
}
