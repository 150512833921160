import axios from "axios";
import { ObjectDataSource } from "./ObjecDatatSource";

export class MailScenarioObjectDataSource extends ObjectDataSource {
  constructor(id: number, model: any = {}) {
    super({
      id,
      model,
      className: "mailscenario",
      config: {},
    });
  }

  public get mailUrl() {
    return `${this.baseUrl}/sendEmail`;
  }

  public async sendRegistrationLetter() {
    try {
      await axios.post(`${this.mailUrl}/successRegistration`);
    } catch (error) {
      throw error;
    }
  }
}
